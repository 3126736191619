.rule__container {   
    position: relative;
    overflow: hidden;  
    width: 100%;    
    padding-top: 56.25%; 
  }
  .responsive-iframe {  
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;  
    width: 100%;
    min-height: 600px;   
  }

  @media (max-width: 1280px) {
    .rule__container {
      padding-top: 90%; 
  }
}

