.footer-text1 {
  max-width: 37.3rem;
  text-align: center;
  margin: auto;
  font-weight: normal;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.2rem;
}
.form__policy div {
  max-height: 8em;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: pre-wrap;
}

.pdfprint {
  width: 620px;
  font-size: 12px;
  overflow: hidden;
  color: black;
  letter-spacing: 0.1px;
  font-weight: 600;
  font-family:Arial, Helvetica, sans-serif
}
.pdfprint .form__policy div{
  max-width: 620px;
  max-height:max-content;
  overflow: visible;
  white-space: pre-wrap;
  /* margin:12px 12px 15px 10px; */
}
.pdfprint .signature-pdf {
  margin-top: 30px;
  max-width: 40% !important
}
.pdfprint .signature-pdf canvas{
  background-color: transparent !important
}

.pdfprint .checkbox label:before {
  width: 1.8rem;
  height: 1.5rem;
  left: 0.8rem;
  top: 0;
}


.pdf-checkbox label {
  font-size: 12px;
  margin-bottom: 10px;
  font-family:Arial, Helvetica, sans-serif;
  letter-spacing: 0.1px;


}

.d-none {
  display: none;
}
