:root {
    /* --keyword-default-bg: #000; */
    --keyword-default-bg: black;
    --keyword-default-color: #fff;
    --keyword-default-font-family: "Proxima Nova Rg", sans-serif;
    --keyword-default-button-bg: #fff;
    --keyword-default-button-color: #000;
}

body {
    background-color: var(--keyword-bg, var(--keyword-default-bg));
    color: var(--keyword-color, var(--keyword-default-color));
    font-family: var(--keyword-font-family, var(--keyword-default-font-family));
}

button, .btn {
    background-color: var(--keyword-button-bg, var(--keyword-default-button-bg));
    color: var(--keyword-button-color, var(--keyword-default-button-color));
}
button:hover, .btn:hover {
    filter: brightness(0.9);
}
button:disabled, .btn:disabled {
    filter: contrast(0.25);
}
select, input {
    background-color: var(--keyword-bg, var(--keyword-default-bg));
    color: var(--keyword-color, var(--keyword-default-color));
}
select::placeholder, input::placeholder, select:invalid, select option[value=""] {
    /** select:invalid only happens if <select required> and <option value="" hidden>select something</option> */
    color: var(--keyword-color, var(--keyword-default-color));
    opacity: 0.75;
}
@media (max-width: 1279px) {
    .onlyDesktop {
        display: none;
    }
}
@media (min-width: 1280px) {
    .onlyMobile {
        display: none;
    }
}
